import { ethers } from "ethers";
import { useEnsDomainOrAddress } from "../leaderboardDataProvider";
import Identicon from "react-identicons";

const ACCOUNT_LENGTH = 21;
const LOADING_TEXT = "loading...".padEnd(ACCOUNT_LENGTH);

function AccountName(props) {
  const isAddressValid = ethers.utils.isAddress(props.address);
  const [address, addressLoading] = useEnsDomainOrAddress(props.address);

  const shortenAccount = account => {
    if (account.length > ACCOUNT_LENGTH)
      return `${account.substring(0, 11)}...${account.slice(-8)}`;
    else return account;
  };

  const accountDisplay = shortenAccount(address);

  return (
    <div className="account">
      <Identicon
        string={accountDisplay}
        fg="#a9e2b0"
        bg="#2a313e"
        size={16}
        className="identicon"
      />
      {addressLoading ? LOADING_TEXT : isAddressValid && `${accountDisplay}`}
    </div>
  );
}

export default AccountName;
