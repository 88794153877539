import cx from "classnames";

const PositionsTabs = props => {
  return (
    <div className="positions-tab-group">
      <div
        className={cx("positions-tab", {
          active: props.activePositionsTab === "open",
        })}
        onClick={() => props.setActivePositionsTab("open")}
      >
        <h3>open positions</h3>
      </div>
      <div
        className={cx("positions-tab", {
          active: props.activePositionsTab === "settled",
        })}
        onClick={() => props.setActivePositionsTab("settled")}
      >
        <h3>settled positions</h3>
      </div>
    </div>
  );
};

export default PositionsTabs;
