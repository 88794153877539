import TransactionLink from "./TransactionLink";
import { priceToFixed } from "../helpers";
import moment from "moment/moment";

const CommonColumns = props => {
  return (
    <>
      <td data-title="pnl (USD)" className="table-column text-right">
        {Number(props.delta).toFixed(2)}
      </td>

      <td data-title="entry (USD)" className="table-column text-right">
        {priceToFixed(props.avgPrice, props.indexToken)}
      </td>

      <td data-title="size (USD)" className="table-column text-right">
        {Number(props.size).toFixed(2)}
      </td>

      <td data-title="coll. (USD)" className="table-column text-right">
        {Number(props.collateral).toFixed(2)}
      </td>

      <td data-title="entered" className="table-column text-right">
        {moment.unix(props.timestamp).format("DD/MM/YYYY HH:mm:ss")}
      </td>

      <td data-title="tx" className="table-column">
        <TransactionLink tx={props.tx} />
      </td>
    </>
  );
};

export default CommonColumns;
