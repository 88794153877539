import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { RiLoader5Fill } from "react-icons/ri";
import cx from "classnames";

import {
  yaxisFormatterNumber,
  yaxisFormatterPercent,
  yaxisFormatter,
  tooltipLabelFormatter,
  tooltipLabelFormatterUnits,
  tooltipFormatter,
  tooltipFormatterNumber,
  tooltipFormatterPercent,
  formatNumber,
  CHART_HEIGHT,
  YAXIS_WIDTH,
  COLORS,
  GREEN,
  RED,
  convertToPercents,
} from "../helpers";

import {
  LineChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Cell,
} from "recharts";

import ChartWrapper from "../components/ChartWrapper";
import VolumeChart from "../components/VolumeChart";
import FeesChart from "../components/FeesChart";
import GenericChart from "../components/GenericChart";
import DateRangeSelect from "../components/DateRangeSelect";

import {
  useVolumeData,
  useTotalVolumeFromServer,
  useVolumeDataFromServer,
  useFeesData,
  useGlpData,
  useAumPerformanceData,
  useCoingeckoPrices,
  useGlpPerformanceData,
  useTradersData,
  useSwapSources,
  useFundingRateData,
  useUsersData,
  useLastSubgraphBlock,
  useLastBlock,
  useVaultTvl,
} from "../dataProvider";
import { tokenAddresses } from "../addresses";
import PoolAmountChart from "../components/PoolAmountChart";
import TradersProfitLossChart from "../components/TradersProfitLossChart";

const NOW = Math.floor(Date.now() / 1000);

function Arbitrum(props) {
  const DEFAULT_GROUP_PERIOD = 86400;
  const [groupPeriod] = useState(DEFAULT_GROUP_PERIOD);

  const [dataRange, setDataRange] = useState({
    fromValue: moment().subtract(2, "month").toDate(),
    toValue: null,
  });

  const { mode } = props;

  const from = dataRange.fromValue
    ? Math.floor(+new Date(dataRange.fromValue) / 1000)
    : undefined;
  const to = dataRange.toValue
    ? Math.floor(+new Date(dataRange.toValue) / 1000)
    : NOW;

  const params = { from, to, groupPeriod };

  const [fundingRateData, fundingRateLoading] = useFundingRateData(params);

  const [volumeData, volumeLoading] = useVolumeData(params);
  const [totalVolumeData, totalVolumeLoading] = useVolumeData({});
  const [totalVolume, totalVolumeDelta] = useMemo(() => {
    if (!totalVolumeData) {
      return [];
    }
    const total = totalVolumeData[totalVolumeData.length - 1]?.cumulative;
    const delta =
      total - totalVolumeData[totalVolumeData.length - 2]?.cumulative;
    return [total, delta];
  }, [totalVolumeData]);

  const [feesData, feesLoading] = useFeesData(params);
  const [totalFeesData, totalFeesLoading] = useFeesData({});
  const [totalFees, totalFeesDelta] = useMemo(() => {
    if (!totalFeesData) {
      return [];
    }
    const total = totalFeesData[totalFeesData.length - 1]?.cumulative;
    const delta = total - totalFeesData[totalFeesData.length - 2]?.cumulative;
    return [total, delta];
  }, [totalFeesData]);

  const [glpData, glpLoading] = useGlpData(params);
  const [totalAum, totalAumDelta] = useMemo(() => {
    if (!glpData) {
      return [];
    }
    const total = glpData[glpData.length - 1]?.aum;
    const delta = total - glpData[glpData.length - 2]?.aum;
    return [total, delta];
  }, [glpData]);
  const vaultTvl = useVaultTvl();

  const [aumPerformanceData, aumPerformanceLoading] =
    useAumPerformanceData(params);
  const [glpPerformanceData, glpPerformanceLoading] = useGlpPerformanceData(
    glpData,
    feesData,
    params,
  );

  const [tradersData, tradersLoading] = useTradersData(params);
  const [openInterest, openInterestDelta] = useMemo(() => {
    if (!tradersData) {
      return [];
    }
    const total = tradersData.data[tradersData.data.length - 1]?.openInterest;
    const delta =
      total - tradersData.data[tradersData.data.length - 2]?.openInterest;
    return [total, delta];
  }, [tradersData]);
  const [swapSources, swapSourcesLoading] = useSwapSources(params);
  const swapSourcesKeys = Object.keys(
    (swapSources || []).reduce((memo, el) => {
      Object.keys(el).forEach(key => {
        if (key === "all" || key === "timestamp") return;
        memo[key] = true;
      });
      return memo;
    }, {}),
  );

  const [usersData, usersLoading] = useUsersData(params);
  const [totalUsers, totalUsersDelta] = useMemo(() => {
    if (!usersData) {
      return [null, null];
    }
    const total = usersData[usersData.length - 1]?.uniqueCountCumulative;
    const prevTotal = usersData[usersData.length - 2]?.uniqueCountCumulative;
    const delta = total && prevTotal ? total - prevTotal : null;
    return [total, delta];
  }, [usersData]);

  const [lastSubgraphBlock, _, lastSubgraphBlockError] = useLastSubgraphBlock();
  const [lastBlock] = useLastBlock();

  const isObsolete =
    lastSubgraphBlock &&
    lastBlock &&
    lastBlock.timestamp - lastSubgraphBlock.timestamp > 3600;

  const [isExperiment, setIsExperiment] = useState(false);
  useEffect(() => {
    setIsExperiment(window.localStorage.getItem("experiment"));
  }, [setIsExperiment]);

  const onDateRangeChange = dates => {
    const [start, end] = dates;
    setDataRange({ fromValue: start, toValue: end });
  };

  const dateRangeOptions = [
    {
      label: "last month",
      id: 1,
    },
    {
      label: "last 2 months",
      id: 2,
    },
    {
      label: "last 3 months",
      id: 3,
    },
    {
      label: "all time",
      id: 4,
      isDefault: true,
    },
  ];

  const hlpMin = glpData && Math.min(...glpData.map(d => d.hlpPrice));
  const hlpMax = glpData && Math.max(...glpData.map(d => d.hlpPrice));
  const hlpBuffer = glpData && (hlpMax - hlpMin) * 0.05;

  const aumHlpSupplyMin =
    glpData && Math.min(...glpData.map(d => Math.min(d.hlpSupply, d.aum)));
  const aumHlpSupplyMax =
    glpData && Math.max(...glpData.map(d => Math.max(d.hlpSupply, d.aum)));
  const aumHlpSupplyBuffer =
    glpData && (aumHlpSupplyMax - aumHlpSupplyMin) * 0.05;

  return (
    <div className="Home">
      <div className="page-title-section">
        <div className="page-title-block">
          <h1>
            <span className="current-page">analytics</span> /{" "}
            <a href="/leaderboard" className="page-link">
              leaderboard
            </a>
          </h1>
          {lastSubgraphBlock && lastBlock && (
            <p className={cx("page-description", { warning: isObsolete })}>
              {isObsolete && "Data is obsolete. "}
              updated {moment(lastSubgraphBlock.timestamp * 1000).fromNow()}
              &nbsp;at block{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://arbiscan.io/block/${lastSubgraphBlock.number}`}
              >
                {lastSubgraphBlock.number}
              </a>
            </p>
          )}
          {lastSubgraphBlockError && (
            <p className="page-description warning">
              Subgraph data is temporarily unavailable.
            </p>
          )}
        </div>
        <div className="form date-form">
          <DateRangeSelect
            options={dateRangeOptions}
            startDate={dataRange.fromValue}
            endDate={dataRange.toValue}
            onChange={onDateRangeChange}
          />
        </div>
      </div>
      <div className="chart-grid">
        <div className="chart-cell stats">
          {totalVolume !== undefined ? (
            <>
              <div className="total-stat-label">total volume</div>
              <div className="total-stat-value">
                {formatNumber(totalVolume, { currency: true })}
                {totalVolumeDelta && (
                  <span
                    className="total-stat-delta plus"
                    title="Change since previous day"
                  >
                    +
                    {formatNumber(totalVolumeDelta, {
                      currency: true,
                      compact: true,
                    })}
                  </span>
                )}
              </div>
            </>
          ) : (
            <RiLoader5Fill size="3em" className="loader" />
          )}
        </div>
        <div className="chart-cell stats">
          {totalFees ? (
            <>
              <div className="total-stat-label">total fees</div>
              <div className="total-stat-value">
                {formatNumber(totalFees, { currency: true })}
                <span
                  className="total-stat-delta plus"
                  title="Change since previous day"
                >
                  +
                  {formatNumber(totalFeesDelta, {
                    currency: true,
                    compact: true,
                  })}
                </span>
              </div>
            </>
          ) : feesLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : null}
        </div>
        <div className="chart-cell stats">
          {totalAum ? (
            <>
              <div className="total-stat-label">hLP pool</div>
              <div className="total-stat-value">
                ${Math.round(vaultTvl).toLocaleString()}
                <span
                  className={cx(
                    "total-stat-delta",
                    totalAumDelta > 0 ? "plus" : "minus",
                  )}
                  title="Change since previous day"
                >
                  {totalAumDelta > 0 ? "+" : ""}
                  {formatNumber(totalAumDelta, {
                    currency: true,
                    compact: true,
                  })}
                </span>
              </div>
            </>
          ) : glpLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : null}
        </div>
        <div className="chart-cell stats">
          {totalUsers ? (
            <>
              <div className="total-stat-label">total users</div>
              <div className="total-stat-value">
                {formatNumber(totalUsers)}
                <span
                  className="total-stat-delta plus"
                  title="Change since previous day"
                >
                  +{formatNumber(totalUsersDelta)}
                </span>
              </div>
            </>
          ) : usersLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : null}
        </div>
        <div className="chart-cell stats">
          {openInterest ? (
            <>
              <div className="total-stat-label">open interest</div>
              <div className="total-stat-value">
                {formatNumber(openInterest, { currency: true })}
                <span
                  className={cx(
                    "total-stat-delta",
                    openInterestDelta > 0 ? "plus" : "minus",
                  )}
                  title="Change since previous day"
                >
                  {openInterestDelta > 0 ? "+" : ""}
                  {formatNumber(openInterestDelta, {
                    currency: true,
                    compact: true,
                  })}
                </span>
              </div>
            </>
          ) : tradersLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : null}
        </div>
        <div className="chart-cell">
          <VolumeChart
            data={volumeData}
            loading={volumeLoading}
            chartHeight={CHART_HEIGHT}
            yaxisWidth={YAXIS_WIDTH}
            xaxisTickFormatter={tooltipLabelFormatter}
            yaxisTickFormatter={yaxisFormatter}
            tooltipLabelFormatter={tooltipLabelFormatter}
            tooltipFormatter={tooltipFormatter}
          />
        </div>
        <div className="chart-cell">
          <FeesChart
            data={feesData}
            loading={feesLoading}
            chartHeight={CHART_HEIGHT}
            yaxisWidth={YAXIS_WIDTH}
          />
        </div>
        <div className="chart-cell">
          <ChartWrapper
            title="aum &amp; hLP supply"
            loading={!glpData}
            data={glpData}
            csvFields={[{ key: "aum" }, { key: "hlpSupply" }]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={glpData}>
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  dataKey="hlpSupply"
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                  domain={[
                    aumHlpSupplyMin - aumHlpSupplyBuffer,
                    aumHlpSupplyMax + aumHlpSupplyBuffer,
                  ]}
                />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={2}
                  unit="$"
                  dot={false}
                  dataKey="aum"
                  stackId="a"
                  name="aum"
                  stroke={COLORS[0]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={2}
                  dot={false}
                  dataKey="hlpSupply"
                  stackId="a"
                  name="hLP Supply"
                  stroke={COLORS[1]}
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <ChartWrapper title="hLP price" loading={!glpData}>
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={glpData}>
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  dataKey="hlpPrice"
                  tickFormatter={d => "$" + d.toFixed(2)}
                  width={YAXIS_WIDTH}
                  domain={[hlpMin - hlpBuffer, hlpMax + hlpBuffer]}
                />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Line
                  isAnimationActive={false}
                  dot={false}
                  type="monotone"
                  strokeWidth={2}
                  dataKey="hlpPrice"
                  name="hLP price (in USD)"
                  stroke={COLORS[1]}
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <PoolAmountChart from={from} to={to} />
        </div>
        {isExperiment && (
          <div className="chart-cell experiment">
            <ChartWrapper title="Performance vs. Index" loading={glpLoading}>
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                <LineChart data={glpPerformanceData}>
                  <CartesianGrid strokeDasharray="10 10" />
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={tooltipLabelFormatter}
                    minTickGap={30}
                  />
                  <YAxis
                    dataKey="performanceSyntheticCollectedFees"
                    domain={[80, 120]}
                    unit="%"
                    tickFormatter={yaxisFormatterNumber}
                    width={YAXIS_WIDTH}
                  />
                  <Tooltip
                    formatter={tooltipFormatterNumber}
                    labelFormatter={tooltipLabelFormatter}
                    contentStyle={{ textAlign: "left" }}
                  />
                  <Legend />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSyntheticCollectedFees"
                    name="collected fees"
                    stroke={COLORS[0]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSyntheticDistributedUsd"
                    name="distributed USD"
                    stroke={COLORS[1]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSyntheticDistributedEth"
                    name="distributed ETH"
                    stroke={COLORS[2]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSynthetic"
                    name="no fees"
                    stroke={COLORS[3]}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartWrapper>
          </div>
        )}
        {isExperiment && (
          <div className="chart-cell experiment">
            <ChartWrapper title="Performance vs. ETH LP" loading={glpLoading}>
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                <LineChart data={glpPerformanceData}>
                  <CartesianGrid strokeDasharray="10 10" />
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={tooltipLabelFormatter}
                    minTickGap={30}
                  />
                  <YAxis
                    dataKey="performanceLpEthCollectedFees"
                    domain={[80, 120]}
                    unit="%"
                    tickFormatter={yaxisFormatterNumber}
                    width={YAXIS_WIDTH}
                  />
                  <Tooltip
                    formatter={tooltipFormatterNumber}
                    labelFormatter={tooltipLabelFormatter}
                    contentStyle={{ textAlign: "left" }}
                  />
                  <Legend />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEthCollectedFees"
                    name="collected fees"
                    stroke={COLORS[0]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEthDistributedUsd"
                    name="distributed USD"
                    stroke={COLORS[1]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEthDistributedEth"
                    name="distributed ETH"
                    stroke={COLORS[2]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEth"
                    name="no fees"
                    stroke={COLORS[3]}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartWrapper>
          </div>
        )}
        <div className="chart-cell">
          <ChartWrapper
            title="traders net pnl"
            loading={tradersLoading}
            data={tradersData?.data}
            csvFields={[
              { key: "pnl", name: "Net PnL" },
              { key: "pnlCumulative", name: "cumulative pnl" },
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <ComposedChart data={tradersData?.data}>
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  domain={[
                    -tradersData?.stats.maxAbsCumulativePnl * 1.05,
                    tradersData?.stats.maxAbsCumulativePnl * 1.05,
                  ]}
                  orientation="right"
                  yAxisId="right"
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                  tick={{ fill: COLORS[4] }}
                />
                <YAxis
                  domain={[
                    -tradersData?.stats.maxAbsPnl * 1.05,
                    tradersData?.stats.maxAbsPnl * 1.05,
                  ]}
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={tooltipFormatter}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Bar
                  type="monotone"
                  fill={mode == "dark" ? "#FFFFFF" : "#000000"}
                  dot={false}
                  dataKey="pnl"
                  name="net pnl"
                >
                  {(tradersData?.data || []).map((item, i) => {
                    return (
                      <Cell
                        key={`cell-${i}`}
                        fill={item.pnl > 0 ? COLORS[1] : COLORS[8]}
                      />
                    );
                  })}
                </Bar>
                <Line
                  type="monotone"
                  strokeWidth={2}
                  stroke={COLORS[4]}
                  dataKey="currentPnlCumulative"
                  name="cumulative pnl"
                  yAxisId="right"
                />
              </ComposedChart>
            </ResponsiveContainer>
            <div className="chart-description">
              <p>closed positions only, excluding fees</p>
            </div>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <TradersProfitLossChart
            loading={tradersLoading}
            tradersData={tradersData}
            yaxisWidth={YAXIS_WIDTH}
            chartHeight={CHART_HEIGHT}
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={tradersLoading}
            title="open interest"
            data={tradersData?.data.map(item => ({
              all: item.openInterest,
              ...item,
            }))}
            controls={{
              convertToPercents: convertToPercents,
            }}
            yaxisDataKey="all"
            items={[
              { key: "shortOpenInterest", name: "short", color: COLORS[8] },
              { key: "longOpenInterest", name: "long", color: COLORS[1] },
            ]}
            type="Bar"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={fundingRateLoading}
            title="borrowing rate annualised"
            data={fundingRateData}
            yaxisDataKey="ETH"
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={Object.values(tokenAddresses).map(symbol => ({
              key: symbol,
            }))}
            type="Line"
            yaxisDomain={[0, 90 /* ~87% is a maximum yearly borrow rate */]}
            isCoinChart={true}
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={aumPerformanceLoading}
            title="aum performance annualised"
            data={aumPerformanceData}
            yaxisDataKey="apr"
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={[{ key: "apr", name: "APR", color: COLORS[0] }]}
            description="daily fees / hLP pool * 365 days * 100"
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={aumPerformanceLoading}
            title="aum daily usage"
            data={aumPerformanceData}
            yaxisDataKey="usage"
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={[{ key: "usage", name: "daily usage", color: COLORS[4] }]}
            description="daily volume / hLP pool * 100"
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={usersLoading}
            title="unique users"
            data={usersData}
            truncateYThreshold={6500}
            yaxisDataKey="uniqueSum"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "uniqueSwapCount", name: "swaps" },
              { key: "uniqueMarginCount", name: "margin trading" },
              { key: "uniqueMintBurnCount", name: "mint & burn hLP" },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={usersLoading}
            title="new users"
            data={usersData?.map(item => ({ ...item, all: item.newCount }))}
            truncateYThreshold={6000}
            yaxisDataKey="newCount"
            rightYaxisDataKey="uniqueCountCumulative"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "newSwapCount", name: "swap" },
              { key: "newMarginCount", name: "margin trading" },
              { key: "newMintBurnCount", name: "mint & burn" },
              {
                key: "cumulativeNewUserCount",
                name: "cumulative",
                type: "Line",
                yAxisId: "right",
                strokeWidth: 2,
                color: COLORS[4],
              },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={usersLoading}
            title="new vs. existing users"
            data={usersData?.map(item => ({ ...item, all: item.uniqueCount }))}
            truncateYThreshold={7000}
            yaxisDataKey="uniqueCount"
            rightYaxisDataKey="oldPercent"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "newCount", name: "new" },
              { key: "oldCount", name: "existing" },
              {
                key: "oldPercent",
                name: "existing %",
                yAxisId: "right",
                type: "Line",
                strokeWidth: 2,
                color: COLORS[4],
                unit: "%",
              },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={usersLoading}
            title="user actions"
            data={(usersData || []).map(item => ({
              ...item,
              all: item.actionCount,
            }))}
            truncateYThreshold={25000}
            yaxisDataKey="actionCount"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "actionSwapCount", name: "swaps" },
              { key: "actionMarginCount", name: "margin trading" },
              { key: "actionMintBurnCount", name: "mint & burn hLP" },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={swapSourcesLoading}
            title="swap sources"
            data={swapSources}
            items={swapSourcesKeys.map(key => ({ key }))}
          />
        </div>
      </div>
    </div>
  );
}

export default Arbitrum;
