import React, { useState, useEffect } from "react";
import { Route, Switch, NavLink, BrowserRouter } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import Arbitrum from "./views/Arbitrum";
import Leaderboard from "./views/Leaderboard";
import Account from "./views/Account";
// import Referrals from './views/Referrals';
// import Avalanche from './views/Avalanche';
// import Trading from './views/Trading';
import "./App.css";
import logoIcon from "./img/handle.fiLogoLightNew.svg";
import darkLogoIcon from "./img/handle.fiLogoLightNew.svg";
import { FiX } from "react-icons/fi";

function AppHeaderLinks({ mode, small, clickCloseIcon }) {
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <div
            className="App-header-menu-icon-block"
            onClick={() => clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
          <NavLink
            exact
            activeClassName="active"
            className="App-header-link-main"
            to="/"
          >
            <img src={logoIcon} alt="handle Logo" />
          </NavLink>
        </div>
      )}
    </div>
  );
}

const App = () => {
  const [mode, setMode] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(undefined);

  const slideVariants = {
    hidden: { x: "-100%" },
    visible: { x: 0 },
  };

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {
    const savedMode = window.localStorage.getItem("mode");
    const targetMode = savedMode == "light" ? "light" : "dark";
    document.querySelector("body").style.backgroundColor =
      targetMode == "dark" ? "#101124" : "#f6f9ff";
    setMode(targetMode);
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        {mode && (
          <div className={cx("App", mode)}>
            {isDrawerVisible && (
              <AnimatePresence>
                {isDrawerVisible && (
                  <motion.div
                    className="App-header-backdrop"
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={fadeVariants}
                    transition={{ duration: 0.2 }}
                    onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                  ></motion.div>
                )}
              </AnimatePresence>
            )}
            <div className="nav">
              <div className="nav-left">
                <a
                  href="https://app.handle.fi"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-logo"
                >
                  <img
                    height="36"
                    alt="handle logo"
                    src={darkLogoIcon}
                    className="border-50pc"
                  />
                  <span style={{ marginLeft: 8 }}>handle.fi</span>
                </a>
                <span className="beta-warning">
                  <i className="fal fa-exclamation-triangle" />
                  <span className="beta-warning-text">beta</span>
                </span>
              </div>
              <div className="nav-right">
                <a
                  href="https://app.handle.fi/trade"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link"
                >
                  trade
                </a>
              </div>
              <div id="blur" />
            </div>

            <AnimatePresence>
              {isDrawerVisible && (
                <motion.div
                  onClick={() => setIsDrawerVisible(false)}
                  className="App-header-links-container App-header-drawer"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={slideVariants}
                  transition={{ duration: 0.2 }}
                >
                  <AppHeaderLinks
                    mode={mode}
                    small
                    clickCloseIcon={() => setIsDrawerVisible(false)}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <div className="content">
              <Route
                exact
                path="/"
                render={props => <Arbitrum {...props} mode={mode} />}
              />
              <Route
                path="/leaderboard"
                render={props => <Leaderboard {...props} mode={mode} />}
              />
              <Route
                path="/account"
                render={props => <Account {...props} mode={mode} />}
              />
            </div>
          </div>
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default App;
