import { convertToPercents } from "../helpers";

export function useChartData() {
  const csvFields = [
    { key: "swap", name: "swap" },
    { key: "margin", name: "margin trading" },
    { key: "mint", name: "mint hLP" },
    { key: "burn", name: "burn hLP" },
    { key: "liquidation", name: "liquidation" },
    { key: "cumulative", name: "Cumulative" },
  ];

  const convertToPercentsHandler = data =>
    convertToPercents(data, {
      ignoreKeys: ["cumulative", "movingAverageAll"],
      totalKey: "all",
    });

  const controls = {
    convertToPercents: convertToPercentsHandler,
  };

  return {
    csvFields,
    controls,
  };
}
