import { useLocation } from "react-router-dom";
import { useMemo } from "react";

function useQuery(query) {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  return useMemo(() => {
    if (query) return searchParams.get(query);

    return searchParams;
  }, [search]);
}

export default useQuery;
