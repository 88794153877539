import {
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
  Line,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function Chart(props) {
  const { 
    chartHeight, 
    formattedData, 
    tooltipLabelFormatter, 
    tooltipFormatter, 
    viewState, 
    viewSettings, 
    yaxisFormatter, 
    yaxisWidth, 
    COLORS 
  } = props;

  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <ComposedChart data={formattedData}>
        <CartesianGrid strokeDasharray="10 10" />
        <XAxis
          dataKey="timestamp"
          tickFormatter={tooltipLabelFormatter}
          minTickGap={30}
        />

        <YAxis
          dataKey="all"
          interval="preserveStartEnd"
          tickCount={viewState.isPercentsView ? undefined : 7}
          tickFormatter={viewSettings.yaxisTickFormatter}
          width={yaxisWidth}
        />

        <YAxis
          dataKey="cumulative"
          orientation="right"
          yAxisId="right"
          tickFormatter={yaxisFormatter}
          width={yaxisWidth}
        />

        <Tooltip
          formatter={tooltipFormatter}
          labelFormatter={tooltipLabelFormatter}
          contentStyle={{ textAlign: "left" }}
        />

        <Legend />
        <Bar
          unit={viewSettings.itemsUnit}
          isAnimationActive={false}
          type="monotone"
          dataKey="swap"
          stackId="a"
          name="swap"
          fill={COLORS[0]}
        />
        <Bar
          unit={viewSettings.itemsUnit}
          isAnimationActive={false}
          type="monotone"
          dataKey="mint"
          stackId="a"
          name="mint hLP"
          fill={COLORS[1]}
        />
        <Bar
          unit={viewSettings.itemsUnit}
          isAnimationActive={false}
          type="monotone"
          dataKey="burn"
          stackId="a"
          name="burn hLP"
          fill={COLORS[2]}
        />
        <Bar
          unit={viewSettings.itemsUnit}
          isAnimationActive={false}
          type="monotone"
          dataKey="liquidation"
          stackId="a"
          name="liquidation"
          fill={COLORS[3]}
        />
        <Bar
          unit={viewSettings.itemsUnit}
          isAnimationActive={false}
          type="monotone"
          dataKey="margin"
          stackId="a"
          name="margin trading"
          fill={COLORS[4]}
        />
        <Line
          isAnimationActive={false}
          type="monotone"
          strokeWidth={3}
          dot={false}
          stroke={COLORS[12]}
          dataKey="cumulative"
          yAxisId="right"
          name="cumulative"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}