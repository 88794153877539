import {
  COLORS,
  tooltipFormatter,
  tooltipLabelFormatter,
  yaxisFormatter,
} from "../helpers";
import ChartWrapper from "./ChartWrapper";
import Chart from "./Chart";
import { useChartData } from "../hooks/useChartData";
import { useChartViewState } from "../hooks/useChartViewState";

export default function VolumeChart(props) {
  const { data, loading, chartHeight, yaxisWidth } = props;

  const { csvFields, controls } = useChartData();
  const { viewState, togglePercentView, formattedData, ...viewSettings } =
    useChartViewState({ controls, data });

  return (
    <ChartWrapper
      title="volume"
      loading={loading}
      csvFields={csvFields}
      data={formattedData}
      controls={controls}
      viewState={viewState}
      togglePercentView={togglePercentView}
    >
      <Chart
        chartHeight={chartHeight}
        formattedData={formattedData}
        tooltipLabelFormatter={tooltipLabelFormatter}
        tooltipFormatter={tooltipFormatter}
        viewState={viewState}
        viewSettings={viewSettings}
        yaxisFormatter={yaxisFormatter}
        yaxisWidth={yaxisWidth}
        COLORS={COLORS}
      />
    </ChartWrapper>
  );
}
