const TransactionLink = props => {
  return (
    <a
      href={`https://arbiscan.io/tx/${props.tx}`}
      rel="noreferrer noopener"
      target="_blank"
    >
      link
    </a>
  );
};

export default TransactionLink;
