import cx from "classnames";

const Pagination = props => {
  return (
    <div className="pagination-group">
      <span className="pagination-label">show:</span>
      <div className="pagination">
        <div
          className={cx("pagination-tab", {
            active: props.pagination === 5,
          })}
          onClick={() => props.setPagination(5)}
        >
          <span>5</span>
        </div>
        <div
          className={cx("pagination-tab", {
            active: props.pagination === 10,
          })}
          onClick={() => props.setPagination(10)}
        >
          <span>10</span>
        </div>
        <div
          className={cx("pagination-tab", {
            active: props.pagination === 20,
          })}
          onClick={() => props.setPagination(20)}
        >
          <span>20</span>
        </div>
        <div
          className={cx("pagination-tab", {
            active: props.pagination === 50,
          })}
          onClick={() => props.setPagination(50)}
        >
          <span>50</span>
        </div>
        <div
          className={cx("pagination-tab", {
            active: props.pagination === 1000,
          })}
          onClick={() => props.setPagination(1000)}
        >
          <span>all</span>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
