import { useState } from "react";
import moment from "moment/moment";
import useQuery from "../hooks/useQuery";
import {
  useActivePositionData,
  useClosePositionData,
} from "../leaderboardDataProvider";
import cx from "classnames";
import AccountName from "../components/AccountName";
import Pagination from "../components/Pagination";
import PositionsTabs from "../components/PositionsTabs";
import CommonColumns from "../components/CommonColumns";

const OPEN_POSITIONS_COLUMNS = 7;
const SETTLED_POSITIONS_COLUMNS = 10;

function Account() {
  const query = useQuery("address");
  const [activePositionsPagination, setActivePositionsPagination] = useState(5);

  // use default pagination and order for account page
  const [activePositions, activePositionsLoading] = useActivePositionData(
    query,
    undefined,
    undefined,
  );
  const [activePositionsTab, setActivePositionsTab] = useState("open");

  const [closePositions, closePositionsLoading] = useClosePositionData(query);

  const noActivePositions =
    !activePositionsLoading &&
    (!activePositions || activePositions.length === 0);
  const noClosePositions =
    !closePositionsLoading && (!closePositions || closePositions.length === 0);

  return (
    <div className="Home">
      <div className="page-title-section">
        <div className="page-title-block">
          <h1 className="page-title-content">
            <span className="current-page">
              <a href={`/account?address=${query}`}>
                <AccountName address={query} />
              </a>
            </span>
            /{" "}
            <a href="/leaderboard" className="page-link">
              leaderboard
            </a>
          </h1>
        </div>
      </div>
      <div>
        <div className="responsive-table">
          <table>
            <thead>
              <tr className="table-control">
                <th
                  colSpan={
                    activePositionsTab === "open"
                      ? OPEN_POSITIONS_COLUMNS
                      : SETTLED_POSITIONS_COLUMNS
                  }
                >
                  <div className="table-header-row">
                    <PositionsTabs
                      activePositionsTab={activePositionsTab}
                      setActivePositionsTab={setActivePositionsTab}
                    />

                    {activePositionsTab === "settled" && (
                      <div className="control-group">
                        <Pagination
                          pagination={activePositionsPagination}
                          setPagination={setActivePositionsPagination}
                        />
                      </div>
                    )}
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {activePositionsTab === "open" && (
                <>
                  <tr className="table-column-headers">
                    <th className="table-column table-column-header text-left">
                      position
                    </th>
                    <th className="table-column table-column-header text-right">
                      leverage
                    </th>
                    <th className="table-column table-column-header text-right">
                      pnl (USD)
                    </th>
                    <th className="table-column table-column-header text-right">
                      entry (USD)
                    </th>
                    <th className="table-column table-column-header text-right">
                      size (USD)
                    </th>
                    <th className="table-column table-column-header text-right">
                      coll. (USD)
                    </th>
                    <th className="table-column table-column-header">
                      entered
                    </th>
                    <th className="table-column table-column-header text-left">
                      tx
                    </th>
                  </tr>

                  {activePositionsLoading && (
                    <tr className="loading">
                      <td colSpan={OPEN_POSITIONS_COLUMNS}>loading...</td>
                    </tr>
                  )}

                  {noActivePositions && (
                    <tr className="none">
                      <td colSpan={OPEN_POSITIONS_COLUMNS}>none</td>
                    </tr>
                  )}

                  {!activePositionsLoading &&
                    !noActivePositions &&
                    activePositions.map((position, i) => (
                      <tr className="table-row account-row" key={position.id}>
                        <td
                          data-title="position"
                          className={cx("table-column", {
                            long: position.isLong,
                            short: !position.isLong,
                          })}
                        >
                          {position.indexToken}/USD{" "}
                          {position.isLong ? "long" : "short"}
                        </td>

                        <td
                          data-title="leverage"
                          className="table-column text-right"
                        >
                          {Number(position.leverage).toFixed(2)}x
                        </td>

                        <CommonColumns
                          delta={position.delta}
                          avgPrice={position.avgPrice}
                          indexToken={position.indexToken}
                          size={position.size}
                          collateral={position.collateral}
                          timestamp={position.timestamp}
                          tx={position.tx}
                        />
                      </tr>
                    ))}
                </>
              )}

              {activePositionsTab === "settled" && (
                <>
                  <tr className="table-column-headers">
                    <th className="table-column table-column-header text-left">
                      position
                    </th>
                    <th className="table-column table-column-header text-right">
                      leverage
                    </th>
                    <th className="table-column table-column-header text-right">
                      pnl (USD)
                    </th>
                    <th className="table-column table-column-header text-right">
                      size (USD)
                    </th>
                    <th className="table-column table-column-header text-right">
                      entered (USD)
                    </th>
                    <th className="table-column table-column-header text-right">
                      closed (USD)
                    </th>
                    <th className="table-column table-column-header text-right">
                      coll. (USD)
                    </th>
                    <th className="table-column table-column-header">
                      entered
                    </th>
                    <th className="table-column table-column-header">closed</th>
                    <th className="table-column table-column-header text-left">
                      tx
                    </th>
                  </tr>

                  {closePositionsLoading && (
                    <tr className="loading">
                      <td colSpan={SETTLED_POSITIONS_COLUMNS}>loading...</td>
                    </tr>
                  )}

                  {noClosePositions && (
                    <tr className="none">
                      <td colSpan={SETTLED_POSITIONS_COLUMNS}>none</td>
                    </tr>
                  )}

                  {!closePositionsLoading &&
                    !noClosePositions &&
                    closePositions.map((position, i) => (
                      <tr className="table-row" key={position.id}>
                        <td
                          className={cx("table-column", {
                            long: position.isLong,
                            short: !position.isLong,
                          })}
                        >
                          {position.indexToken}/USD{" "}
                          {position.isLong ? "long" : "short"}
                        </td>

                        <td className="table-column text-right">
                          {Number(position.leverage).toFixed(2)}x
                        </td>

                        <td className="table-column text-right">
                          {Number(position.pnl).toFixed(2)}
                        </td>

                        <td className="table-column text-right">
                          {Number(position.size).toFixed(2)}
                        </td>

                        <td className="table-column text-right">
                          {Number(position.avgPrice).toFixed(4)}
                        </td>

                        <td className="table-column text-right">
                          {Number(position.closePrice).toFixed(4)}
                        </td>

                        <td className="table-column text-right">
                          {Number(position.collateral).toFixed(2)}
                        </td>

                        <td className="table-column text-right">
                          {moment
                            .unix(position.opened)
                            .format("DD/MM/YYYY HH:mm:ss")}
                        </td>

                        <td className="table-column text-right">
                          {moment
                            .unix(position.timestamp)
                            .format("DD/MM/YYYY HH:mm:ss")}
                        </td>

                        <td className="table-column">
                          <a
                            href={`https://arbiscan.io/tx/${position.tx}`}
                            rel="noreferrer noopener"
                            target="_blank"
                          >
                            link
                          </a>
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Account;
