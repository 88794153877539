import { useMemo, useState } from "react";
import { useGraph } from "./dataProvider";
import { tokenAddresses } from "./addresses";
import { ethers } from "ethers";
import { HlpConfig, TradeUtils } from "handle-sdk";

const HANDLE_SUBGRAPH =
  "https://api.thegraph.com/subgraphs/name/handle-fi/handle-trade";
const PRECISION = ethers.utils.parseUnits("1", HlpConfig.PRICE_DECIMALS);
const ORACLE_DECIMALS = 8;

export function useActivePositionData(
  account,
  pagination = 1000,
  order = "size",
) {
  const query = getActivePositionQuery(account, pagination, "size");
  const [graphData, loading, error] = useGraph(query, {
    subgraphUrl: HANDLE_SUBGRAPH,
  });
  const [config, setConfig] = useState();

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }
    if (!config) {
      HlpConfig.getLoadedConfig("arbitrum")
        .then(setConfig)
        .catch(console.error);
      return null;
    }

    const increasePositions = graphData.increasePositions;
    const tokenConfigs = graphData.tokenConfigs;
    const chainlinkPrices = graphData.chainlinkPrices;

    const data = graphData.activePositions.map(pos =>
      mapActivePosition(
        pos,
        increasePositions,
        tokenConfigs,
        chainlinkPrices,
        config,
      ),
    );

    return data.sort((a, b) => {
      if (Number(a[order]) < Number(b[order])) return 1;
      if (Number(a[order]) > Number(b[order])) return -1;
      return 0;
    });
  }, [graphData, config, order]);

  return [data, loading, error];
}

function getActivePositionQuery(account, pagination, order) {
  const query = `{
    activePositions(
      first: ${pagination}
      orderBy: ${order}
      orderDirection: desc
      ${account ? `where: { account: "${account.toLowerCase()}" }` : ""}
    ) {
      id
      account
      averagePrice
      collateral
      size
      indexToken
      collateralToken
      isLong
      createdAt
      firstIncreaseTransactionHash
    }
    increasePositions(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
    ) {
      key
      price
      timestamp
    }
    tokenConfigs(
      first: 1000
    ) {
      token
      minProfitBasisPoints
    }
    chainlinkPrices(
      first: 1000
      where: {
        period: last
      }
    ) {
      token
      value
    }
  }`;

  return query;
}

function mapActivePosition(
  position,
  increasePositions,
  tokenConfigs,
  chainlinkPrices,
  config,
) {
  const id = position.id;
  const account = position.account;
  const isLong = position.isLong;
  const timestamp = position.createdAt;
  const indexToken = tokenAddresses[position.indexToken.toLowerCase()];
  const collateralToken =
    tokenAddresses[position.collateralToken.toLowerCase()];
  const tx = position.firstIncreaseTransactionHash;

  const collateralBn = ethers.BigNumber.from(position.collateral);
  const collateral = ethers.utils.formatUnits(
    collateralBn,
    HlpConfig.PRICE_DECIMALS,
  );

  const sizeBn = ethers.BigNumber.from(position.size);
  const size = ethers.utils.formatUnits(sizeBn, HlpConfig.PRICE_DECIMALS);

  const avgPriceBn = ethers.BigNumber.from(position.averagePrice);
  const avgPrice = ethers.utils.formatUnits(
    avgPriceBn,
    HlpConfig.PRICE_DECIMALS,
  );

  const leverageBn = sizeBn.mul(PRECISION).div(collateralBn);
  const leverage = ethers.utils.formatUnits(
    leverageBn,
    HlpConfig.PRICE_DECIMALS,
  );

  const latestIncrease = increasePositions.find(
    pos => pos.key.toLowerCase() === position.id.toLowerCase(),
  );
  const { minProfitBasisPoints } = tokenConfigs.find(
    token => token.token.toLowerCase() === position.indexToken.toLowerCase(),
  );
  const latestPriceForToken = chainlinkPrices.find(
    price => price.token.toLowerCase() === position.indexToken.toLowerCase(),
  );
  const indexPriceBn = ethers.BigNumber.from(latestPriceForToken.value).mul(
    ethers.BigNumber.from(10).pow(HlpConfig.PRICE_DECIMALS - ORACLE_DECIMALS),
  );
  const deltaObject = TradeUtils.getPositionDelta(
    indexPriceBn,
    {
      size: sizeBn,
      collateral: collateralBn,
      isLong,
      averagePrice: avgPriceBn,
      lastIncreasedTime: ethers.BigNumber.from(
        latestIncrease?.timestamp || Math.floor(Date.now() / 1000),
      ),
    },
    config,
    minProfitBasisPoints,
  );
  const delta = ethers.utils.formatUnits(
    deltaObject.delta,
    HlpConfig.PRICE_DECIMALS,
  );
  const formattedDelta = deltaObject.hasProfit ? delta : `-${delta}`;

  return {
    id,
    account,
    timestamp,
    avgPrice,
    collateral,
    leverage,
    delta: formattedDelta,
    size,
    indexToken,
    collateralToken,
    isLong,
    tx,
  };
}

export function useClosePositionData(account) {
  const query = getClosePositionQuery(account);
  const [graphData, loading, error] = useGraph(query, {
    subgraphUrl: HANDLE_SUBGRAPH,
  });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    return graphData.closePositions.map(pos => mapClosePosition(pos));
  }, [graphData]);

  return [data, loading, error];
}

function getClosePositionQuery(account) {
  const query = `{
    closePositions(
      first: 1000
      orderBy: realisedPnl
      orderDirection: desc
      where: {
        ${account ? `account: "${account.toLowerCase()}"` : ""}
      }
    ) {
      id
      key
      account
      size
      realisedPnl
      entryFundingRate
      averagePrice
      closePrice
      indexToken
      collateral
      collateralToken
      isLong
      enteredAt
      timestamp
      transaction {
        id
      }
    }
  }`;

  return query;
}

function mapClosePosition(position) {
  const id = position.id;
  const key = position.key;
  const account = position.account;
  const opened = position.enteredAt;
  const timestamp = position.timestamp;
  const isLong = position.isLong;
  const indexToken = tokenAddresses[position.indexToken.toLowerCase()];
  const collateralToken =
    tokenAddresses[position.collateralToken.toLowerCase()];
  const tx = position.transaction.id;

  const pnlBn = ethers.BigNumber.from(position.realisedPnl);
  const pnl = ethers.utils.formatUnits(pnlBn, HlpConfig.PRICE_DECIMALS);

  const avgPriceBn = ethers.BigNumber.from(position.averagePrice);
  const avgPrice = ethers.utils.formatUnits(
    avgPriceBn,
    HlpConfig.PRICE_DECIMALS,
  );

  const closePriceBn = ethers.BigNumber.from(position.closePrice);
  const closePrice = ethers.utils.formatUnits(
    closePriceBn,
    HlpConfig.PRICE_DECIMALS,
  );

  const collateralBn = ethers.BigNumber.from(position.collateral);
  const collateral = ethers.utils.formatUnits(
    collateralBn,
    HlpConfig.PRICE_DECIMALS,
  );

  const sizeBn = ethers.BigNumber.from(position.size);
  const size = ethers.utils.formatUnits(sizeBn, HlpConfig.PRICE_DECIMALS);

  const leverageBn = sizeBn.mul(PRECISION).div(collateralBn);
  const leverage = ethers.utils.formatUnits(
    leverageBn,
    HlpConfig.PRICE_DECIMALS,
  );

  return {
    id,
    key,
    account,
    pnl,
    collateral,
    size,
    avgPrice,
    closePrice,
    leverage,
    isLong,
    indexToken,
    collateralToken,
    opened,
    timestamp,
    tx,
  };
}

export function useSummedClosePositionData(
  pagination = "24hours",
  direction = "desc",
) {
  const timestampFilter = useTimestampFilter(pagination);
  const query = getSummedClosePositionQuery(timestampFilter);
  const [graphData, loading, error] = useGraph(query);

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const accountsWithData = {};
    graphData.closePositions.forEach(position => {
      const id = position.id;
      const pnl = ethers.BigNumber.from(position.realisedPnl);
      const win = !pnl.isNegative();
      const account = position.account;
      const collateral = ethers.BigNumber.from(position.collateral);
      const size = ethers.BigNumber.from(position.size);

      // if the account is already cached
      if (!!accountsWithData[account]) {
        const leverage = size.mul(PRECISION).div(collateral);
        const average = accountsWithData[account].averageLeverage.past.reduce(
          (prev, cur) => prev.add(cur),
        );
        accountsWithData[account] = {
          id,
          pnl: accountsWithData[account].pnl.add(pnl),
          // [win, loss]
          winOrLoss: win
            ? [
                accountsWithData[account].winOrLoss[0] + 1,
                accountsWithData[account].winOrLoss[1],
              ]
            : [
                accountsWithData[account].winOrLoss[0],
                accountsWithData[account].winOrLoss[1] + 1,
              ],
          risk: accountsWithData[account].risk.add(size),
          collateral: accountsWithData[account].collateral.add(collateral),
          averageLeverage: {
            past: [...accountsWithData[account].averageLeverage.past, leverage],
            average: average.div(
              accountsWithData[account].averageLeverage.past.length,
            ),
          },
        };
      } else {
        // else, create a new entry for the account
        const leverage = size.mul(PRECISION).div(collateral);
        accountsWithData[account] = {
          id,
          pnl,
          winOrLoss: win ? [1, 0] : [0, 1],
          risk: size,
          collateral: collateral,
          averageLeverage: {
            past: [leverage],
            average: leverage,
          },
        };
      }
    });

    const accountsWithDataAsArray = Object.keys(accountsWithData).map(acc => ({
      id: accountsWithData[acc].id,
      account: acc,
      pnl: ethers.utils.formatUnits(accountsWithData[acc].pnl, 30),
      winOrLoss: accountsWithData[acc].winOrLoss,
      risk: ethers.utils.formatUnits(accountsWithData[acc].risk, 30),
      collateral: ethers.utils.formatUnits(
        accountsWithData[acc].collateral,
        30,
      ),
      averageLeverage: ethers.utils.formatUnits(
        accountsWithData[acc].averageLeverage.average,
        30,
      ),
    }));

    if (direction === "desc") {
      return accountsWithDataAsArray.sort(
        (a, b) => Number(b.pnl) - Number(a.pnl),
      );
    } else {
      return accountsWithDataAsArray.sort(
        (a, b) => Number(a.pnl) - Number(b.pnl),
      );
    }
  }, [graphData, direction]);

  return [data, loading, error];
}

function useTimestampFilter(pagination) {
  let timestampFilter = useMemo(() => Math.floor(Date.now() / 1000), []); // memoise unix timestamp for current second
  switch (pagination) {
    case "24hours":
      timestampFilter = timestampFilter - 86400; // minus 24 hours
      break;
    case "week":
      timestampFilter = timestampFilter - 604800; // minus a week
      break;
    case "month":
      timestampFilter = timestampFilter - 2629743; // minus a month
      break;
    default:
      timestampFilter = timestampFilter - 86400; // minus 24 hours
  }

  return timestampFilter;
}

function getSummedClosePositionQuery(timestampFilter) {
  const query = `{
    closePositions(
      first: 1000
      where: {
        timestamp_gte: ${timestampFilter}
      }
    ) {
      id
      account
      collateral
      size
      realisedPnl
    }
  }`;

  return query;
}

export function useEnsDomainOrAddress(address) {
  const subgraphUrl = "https://api.thegraph.com/subgraphs/name/ensdomains/ens";
  // query will return array but will always have one entity as it is being searched by address, and addresses are unique
  const query = `{
    domains(
      first: 1
      where: {
        resolvedAddress: "${address.toLowerCase()}"
      }
    ) {
      name
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { subgraphUrl });

  const data = useMemo(() => {
    if (!graphData || graphData.domains.length === 0) {
      return address; // default export should be the original address
    }

    const { name } = graphData.domains[0]; // see above for explanation for [0]

    return name;
  }, [graphData]);

  return [data, loading, error];
}
