import { useState, useEffect } from "react";
import {
  useActivePositionData,
  useSummedClosePositionData,
} from "../leaderboardDataProvider";
import cx from "classnames";
import AccountName from "../components/AccountName";
import Pagination from "../components/Pagination";
import PositionsTabs from "../components/PositionsTabs";
import CommonColumns from "../components/CommonColumns";

const OPEN_POSITIONS_COLUMNS = 10;
const SETTLED_POSITIONS_COLUMNS = 8;

const Leaderboard = () => {
  const [activePositionsPagination, setActivePositionsPagination] = useState(5);
  const [activePositionsOrderBy, setActivePositionsOrderBy] = useState("delta");
  const [activePositionsTab, setActivePositionsTab] = useState("open");

  const [closePositionsPagination, setClosePositionsPagination] =
    useState("24hours");
  const [closePositionsOrderDirection, setClosePositionsOrderDirection] =
    useState("desc");

  const [activePositions, activePositionsLoading] = useActivePositionData(
    undefined,
    activePositionsPagination,
    activePositionsOrderBy,
  );

  useEffect(() => {
    if (activePositionsOrderBy === "delta") {
      activePositions?.sort((a, b) => {
        if (Number(a.delta) < Number(b.delta)) return 1;
        if (Number(a.delta) > Number(b.delta)) return -1;
        return 0;
      });
    }
  }, [
    activePositions,
    activePositionsLoading,
    activePositionsPagination,
    activePositionsOrderBy,
  ]);

  const [summedClosedPositions, summedClosedPositionsLoading] =
    useSummedClosePositionData(
      closePositionsPagination,
      closePositionsOrderDirection,
    );

  const noActivePositions =
    !activePositionsLoading &&
    (!activePositions || activePositions.length === 0);
  const noSummedClosedPositions =
    !summedClosedPositionsLoading &&
    (!summedClosedPositions || summedClosedPositions.length === 0);

  return (
    <div className="Home">
      <div className="page-title-section">
        <div className="page-title-block">
          <h1>
            <span className="current-page">leaderboard</span> /{" "}
            <a href="/" className="page-link">
              analytics
            </a>
          </h1>
        </div>
      </div>

      <div className="responsive-table">
        <table>
          <thead>
            <tr className="table-control">
              <th
                colSpan={
                  activePositionsTab === "open"
                    ? OPEN_POSITIONS_COLUMNS
                    : SETTLED_POSITIONS_COLUMNS
                }
              >
                <div className="table-header-row">
                  <PositionsTabs
                    activePositionsTab={activePositionsTab}
                    setActivePositionsTab={setActivePositionsTab}
                  />

                  {activePositionsTab === "open" && (
                    <div className="control-group">
                      <Pagination
                        pagination={activePositionsPagination}
                        setPagination={setActivePositionsPagination}
                      />

                      <div className="sort-group">
                        <span className="sort-label">sort by:</span>
                        <div
                          className={cx("sort-tab", {
                            active: activePositionsOrderBy === "delta",
                          })}
                          onClick={() => setActivePositionsOrderBy("delta")}
                        >
                          <span>pnl</span>
                        </div>
                        <div
                          className={cx("sort-tab", {
                            active: activePositionsOrderBy === "size",
                          })}
                          onClick={() => setActivePositionsOrderBy("size")}
                        >
                          <span>size</span>
                        </div>
                        <div
                          className={cx("sort-tab", {
                            active: activePositionsOrderBy === "collateral",
                          })}
                          onClick={() =>
                            setActivePositionsOrderBy("collateral")
                          }
                        >
                          <span>collateral</span>
                        </div>
                      </div>
                    </div>
                  )}

                  {activePositionsTab === "settled" && (
                    <div className="control-group">
                      <div className="period-group">
                        <span className="period-label">last:</span>
                        <div className="period">
                          <div
                            className={cx("period-tab", {
                              active: closePositionsPagination === "24hours",
                            })}
                            onClick={() =>
                              setClosePositionsPagination("24hours")
                            }
                          >
                            <span>24h</span>
                          </div>
                          <div
                            className={cx("period-tab", {
                              active: closePositionsPagination === "week",
                            })}
                            onClick={() => setClosePositionsPagination("week")}
                          >
                            <span>1w</span>
                          </div>
                          <div
                            className={cx("period-tab", {
                              active: closePositionsPagination === "month",
                            })}
                            onClick={() => setClosePositionsPagination("month")}
                          >
                            <span>1m</span>
                          </div>
                        </div>
                      </div>

                      <div className="sort-group">
                        <span className="sort-label">dir:</span>
                        <div
                          className={cx("sort-tab", {
                            active: closePositionsOrderDirection === "desc",
                          })}
                          onClick={() =>
                            setClosePositionsOrderDirection("desc")
                          }
                        >
                          <span>desc</span>
                        </div>
                        <div
                          className={cx("sort-tab", {
                            active: closePositionsOrderDirection === "asc",
                          })}
                          onClick={() => setClosePositionsOrderDirection("asc")}
                        >
                          <span>asc</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {activePositionsTab === "open" && (
              <>
                <tr className="table-column-headers">
                  <th className="table-column table-column-header text-right">
                    rank
                  </th>
                  <th className="table-column table-column-header account-table-column text-left">
                    account
                  </th>
                  <th className="table-column table-column-header text-left">
                    position
                  </th>
                  <th className="table-column table-column-header text-right">
                    leverage
                  </th>
                  <th className="table-column table-column-header text-right">
                    pnl (USD)
                  </th>
                  <th className="table-column table-column-header text-right">
                    entry (USD)
                  </th>
                  <th className="table-column table-column-header text-right">
                    size (USD)
                  </th>
                  <th className="table-column table-column-header text-right">
                    coll. (USD)
                  </th>
                  <th className="table-column table-column-header">entered</th>
                  <th className="table-column table-column-header text-left">
                    tx
                  </th>
                </tr>

                {activePositionsLoading && (
                  <tr className="loading">
                    <td colSpan={OPEN_POSITIONS_COLUMNS}>loading...</td>
                  </tr>
                )}

                {noActivePositions && (
                  <tr className="none">
                    <td colSpan={OPEN_POSITIONS_COLUMNS}>none</td>
                  </tr>
                )}

                {!activePositionsLoading &&
                  !noActivePositions &&
                  activePositions.map((position, i) => (
                    <tr className="table-row" key={position.id}>
                      <td data-title="rank" className="table-column text-right">
                        {i + 1}
                      </td>

                      <td data-title="account" className="table-column">
                        <a href={`/account?address=${position.account}`}>
                          <AccountName address={position.account} />
                        </a>
                      </td>

                      <td
                        className={cx("table-column", {
                          long: position.isLong,
                          short: !position.isLong,
                        })}
                        data-title="position"
                      >
                        {position.indexToken}/USD{" "}
                        {position.isLong ? "long" : "short"}
                      </td>

                      <td
                        data-title="leverage"
                        className="table-column text-right"
                      >
                        {Number(position.leverage).toFixed(2)}x
                      </td>

                      <CommonColumns
                        delta={position.delta}
                        avgPrice={position.avgPrice}
                        indexToken={position.indexToken}
                        size={position.size}
                        collateral={position.collateral}
                        timestamp={position.timestamp}
                        tx={position.tx}
                      />
                    </tr>
                  ))}
              </>
            )}

            {activePositionsTab === "settled" && (
              <>
                <tr className="table-column-headers">
                  <th className="table-column table-column-header text-right">
                    rank
                  </th>
                  <th className="table-column table-column-header account-table-column text-left">
                    account
                  </th>
                  <th className="table-column table-column-header text-right">
                    pnl (USD)
                  </th>
                  <th className="table-column table-column-header text-right">
                    pnl%
                  </th>
                  <th className="table-column table-column-header text-center">
                    win/loss
                  </th>
                  <th className="table-column table-column-header text-right">
                    total coll. (USD)
                  </th>
                  <th className="table-column table-column-header text-right">
                    total notional traded (USD)
                  </th>
                  <th className="table-column table-column-header text-right">
                    leverage
                  </th>
                </tr>

                {summedClosedPositionsLoading && (
                  <tr className="loading">
                    <td colSpan={SETTLED_POSITIONS_COLUMNS}>loading...</td>
                  </tr>
                )}

                {noSummedClosedPositions && (
                  <tr className="none">
                    <td colSpan={SETTLED_POSITIONS_COLUMNS}>none</td>
                  </tr>
                )}

                {!summedClosedPositionsLoading &&
                  !noSummedClosedPositions &&
                  summedClosedPositions.map((position, i) => (
                    <tr className="table-row" key={position.id}>
                      <td data-title="rank" className="table-column text-right">
                        {i + 1}
                      </td>

                      <td data-title="account" className="table-column">
                        <a href={`/account?address=${position.account}`}>
                          <AccountName address={position.account} />
                        </a>
                      </td>

                      <td
                        data-title="pnl (USD)"
                        className="table-column text-right"
                      >
                        {Number(position.pnl).toFixed(2)}
                      </td>

                      <td data-title="pnl%" className="table-column text-right">
                        {(
                          (Number(position.pnl) / Number(position.collateral)) *
                          100
                        ).toFixed(2)}
                        %
                      </td>

                      <td
                        data-title="win/loss"
                        className="table-column text-center"
                      >
                        {position.winOrLoss[0]}/{position.winOrLoss[1]}
                      </td>

                      <td
                        data-title="tot. coll. (USD)"
                        className="table-column text-right"
                      >
                        {Number(position.collateral).toFixed(2)}
                      </td>

                      <td
                        data-title="tot. traded (USD)"
                        className="table-column text-right"
                      >
                        {Number(position.risk).toFixed(2)}
                      </td>

                      <td
                        data-title="leverage"
                        className="table-column text-right"
                      >
                        {Number(position.averageLeverage).toFixed(2)}x
                      </td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Leaderboard;
